import { Box, Button, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image, Layout, Cell, Text, Heading, CounterBadge, Table, TableActionCell, Modal, CustomModalLayout, FormField } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Hero from './hero.png';
import { ReactTagManager } from 'react-gtm-ts';
import React, { useEffect } from 'react';
import * as Icons from '@wix/wix-ui-icons-common';
import { get } from "http";
import Divider from "@wix/design-system/dist/types/Card/Divider";

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [settings, setSettings] = React.useState(null as any);
  const [isSkippedOnboarding, setIsSkippedOnboarding] = React.useState(false);
  const [completions, setCompletions] = React.useState([{
    subject: "Thank you for your order",
    expandable: true,
    expanded: false,
  }
  ] as any);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    // getCompletions();
  }, [])


  const BASE_URL = `https://certifiedcode.wixsite.com/experience/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {

      })
  }

  function getSettings() {
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setSettings(data);
      })
      .catch(() => {

      })
  }

  function getCompletions() {
    fetch(BASE_URL + "/completions", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        setCompletions(data)
      })
      .catch(() => {

      })
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=d2bb67ab-6cd6-4745-938a-baf328233720&redirectUrl=https://certifiedcode.wixsite.com/experience/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  // if (!settings) {
  //   return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
  //     <Page height='100vh'>
  //       <Page.Content>
  //         <Box height={"90vh"} direction="vertical" verticalAlign="middle">
  //           <Loader text="Loading" />
  //         </Box>
  //       </Page.Content>
  //     </Page></WixDesignSystemProvider>
  // }

  if (settings && settings?.hooks?.length === 0 && !isSkippedOnboarding) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Card>
        <MarketingPageLayout
          removeImageHorizontalPadding
          removeImageVerticalPadding
          content={
            <Box height="840px" verticalAlign="middle">
              <MarketingPageLayoutContent
                title="Offer human-like experience with AI"
                content="Draft, edit and send email with Experience. Experience is an AI-powered email editor that helps you create beautiful, effective emails in minutes."
                actions={<Box direction="horizontal" gap="SP1">
                  <Button
                    onClick={() => {
                      setIsSkippedOnboarding(true);
                    }}
                  >Start Now</Button>
                  <Button skin='inverted' as='a' href='https://www.wix.com/app-market/add-review/d2bb67ab-6cd6-4745-938a-baf328233720' target='_blank'>How it works</Button>
                </Box>}
              />
            </Box>
          }
          image={<Image borderRadius={0} src={Hero} />}
        />
      </Card>
    </WixDesignSystemProvider >
  }

  const renderRowDetails = (row: any) => {
    if (row.expandable) {
      return <Box height="120px">TEXT</Box>;
    }
  };

  const columns = [
    { title: 'Subject', render: (row: any) => row.subject },
    {
      render: (row: any) =>
        row.expandable ? (
          <TableActionCell
            size="small"
            primaryAction={{
              text: !row.expanded ? 'Expand' : 'Collapse',
              onClick: () => { row.expanded = !row.expanded; },
            }}
          />
        ) : (
          ''
        ),
    },
  ];


  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Page height="60vh">
      <Page.Header
        title="Experience"
        subtitle="Create, edit and send emails with AI"
      />
      <Page.Content>
        <Layout>
          <Cell span={6} rows={3}>
            <Card stretchVertically>
              <Card.Content>
                <Box verticalAlign="middle">
                  <Box direction="vertical">
                    <Box direction="horizontal" gap="SP1"><Heading size="small">Draft Emails</Heading>
                      <CounterBadge>77</CounterBadge></Box>
                    <Text size="small" secondary>
                      Manage all your emails in one place
                    </Text>
                  </Box>
                  <Box marginLeft="SP3" direction="vertical">
                    <Button skin="inverted" size="small">View</Button>
                  </Box>
                </Box>
              </Card.Content>
              <Table
                skin="neutral"
                rowDetails={(row) => renderRowDetails(row)}
                data={completions}
                // @ts-ignore
                columns={columns}
                showLastRowDivider={false}
              >
                <Table.Content />
              </Table>
            </Card>
          </Cell>
          <Cell span={6} rows={1}>
            <Card stretchVertically>
              <Card.Content>
                <Box verticalAlign="middle">
                  <Box direction="vertical">
                    <Box direction="horizontal" gap="SP1"><Heading size="small">Your Settings</Heading></Box>
                    <Text size="small" secondary>
                      Control when to generate emails
                    </Text>
                  </Box>
                  <Box marginLeft="SP3" direction="vertical">
                    <Button skin="inverted" size="small">Manage Settings</Button>
                  </Box>
                </Box>
              </Card.Content>
            </Card>
          </Cell>
          <Cell span={6} rows={1}>
            <Card stretchVertically>
              <Card.Content>
                <Box verticalAlign="middle">
                  <Box direction="vertical">
                    <Box direction="horizontal" gap="SP1"><Heading size="small">Your Business</Heading></Box>
                    <Text size="small" secondary>
                      Update your business info to personalize emails
                    </Text>
                  </Box>
                  <Box marginLeft="SP3" direction="vertical">
                    <Button skin="inverted" size="small">See How</Button>
                  </Box>
                </Box>
              </Card.Content>
            </Card>
          </Cell>
        </Layout>
      </Page.Content>
    </Page></WixDesignSystemProvider>
}

export default App;
